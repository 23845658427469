var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "WorkingTime",
    class: {
      'is-loading': _vm.isFetchingData
    }
  }, [_c('Toolbar', [_c('div', [_c('Dropdown', {
    attrs: {
      "hasSearch": "",
      "placeholder": "Fahrer",
      "items": _vm.drivers.filter(function (d) {
        return d.isVisible;
      }),
      "selected": _vm.selectedEmployee
    },
    on: {
      "onItemSelect": _vm.handleDriverChange
    }
  }), _c('Datepicker', {
    attrs: {
      "monthPicker": "",
      "startDate": _vm.selectedFrom
    },
    on: {
      "onChange": _vm.handleDateChange
    }
  }), _c('Button', {
    attrs: {
      "size": "small",
      "isLoading": _vm.isFetchingData
    },
    on: {
      "onClick": _vm.handleQuery
    }
  }, [_vm._v(" Anzeigen ")]), _c('div', {
    staticClass: "ToolbarActions--Group"
  }, [_vm.selectedEmployee ? _c('Button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'In Umsatz Anzeigen',
      expression: "'In Umsatz Anzeigen'"
    }],
    attrs: {
      "variant": "icon"
    },
    on: {
      "onClick": function onClick($event) {
        return _vm.handleShowOnPage('revenue');
      }
    }
  }, [_c('i', {
    staticClass: "ri-money-dollar-circle-line"
  })]) : _vm._e(), _vm.selectedEmployee ? _c('Button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'In Schichten Anzeigen',
      expression: "'In Schichten Anzeigen'"
    }],
    attrs: {
      "variant": "icon"
    },
    on: {
      "onClick": function onClick($event) {
        return _vm.handleShowOnPage('shifts');
      }
    }
  }, [_c('i', {
    staticClass: "ri-repeat-line"
  })]) : _vm._e()], 1)], 1), _c('div', [_c('Download', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: !this.selectedEmployee ? 'Wählen Sie einen Fahrer aus' : '',
      expression: "!this.selectedEmployee ? 'Wählen Sie einen Fahrer aus' : ''"
    }],
    attrs: {
      "disabled": !this.selectedEmployee,
      "title": "Download PDF",
      "type": "pdf"
    },
    on: {
      "onDownload": _vm.handleDownload
    }
  })], 1)]), _vm.employeeWorkTime.length && _vm.selectedEmployee ? _c('employee-working-time-table', {
    attrs: {
      "workingTimes": this.employeeWorkTime
    },
    on: {
      "onClick": _vm.handleEmployeeRowClick
    }
  }) : _vm.workingTimes.length ? _c('working-time-table', {
    attrs: {
      "workingTimes": this.workingTimes
    },
    on: {
      "onClick": _vm.handleRowClick
    }
  }) : _vm.isFetchingData ? _c('div', {
    staticClass: "Spinner-Wrap"
  }, [_c('spinner')], 1) : _c('EmptyState'), _c('Modal', {
    attrs: {
      "title": "Markieren Sie die Pausen",
      "show": _vm.pauseModalIsActive,
      "action": {
        text: 'Speichern',
        color: 'green',
        callback: _vm.handleSavePauses
      },
      "cancel": {
        text: 'Abbrechen'
      },
      "size": 'medium',
      "isLoading": _vm.isFetchingData
    },
    on: {
      "onModalClose": _vm.handleModalClose
    }
  }, [_c('RowWrap', {
    attrs: {
      "width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_vm.sortedPauses.length > 0 ? _c('Row', {
          key: -1,
          staticClass: "Row-Header",
          attrs: {
            "parentHover": false,
            "items": {
              row: _vm.header
            },
            "index": -1
          },
          on: {
            "onClickColumn": _vm.handlePausesHeaderClick
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  }, _vm._l(_vm.sortedPauses, function (pause, i) {
    return _c('Row', {
      key: i,
      attrs: {
        "items": pause,
        "index": i
      }
    });
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }