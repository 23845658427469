<template>
    <div class="WorkingTime" :class="{ 'is-loading': isFetchingData }">
        <Toolbar>
            <div>
                <Dropdown
                    hasSearch
                    placeholder="Fahrer"
                    :items="drivers.filter(d => d.isVisible)"
                    :selected="selectedEmployee"
                    @onItemSelect="handleDriverChange"
                />
                <Datepicker monthPicker :startDate="selectedFrom" @onChange="handleDateChange" />
                <Button size="small" @onClick="handleQuery" :isLoading="isFetchingData">
                    Anzeigen
                </Button>
                <div class="ToolbarActions--Group">
                    <Button
                        v-if="selectedEmployee"
                        v-tooltip="'In Umsatz Anzeigen'"
                        variant="icon"
                        @onClick="handleShowOnPage('revenue')"
                    >
                        <i class="ri-money-dollar-circle-line"></i>
                    </Button>
                    <Button
                        v-if="selectedEmployee"
                        v-tooltip="'In Schichten Anzeigen'"
                        variant="icon"
                        @onClick="handleShowOnPage('shifts')"
                    >
                        <i class="ri-repeat-line"></i>
                    </Button>
                </div>
            </div>
            <div>
                <Download
                    v-tooltip="!this.selectedEmployee ? 'Wählen Sie einen Fahrer aus' : ''"
                    :disabled="!this.selectedEmployee"
                    title="Download PDF"
                    type="pdf"
                    @onDownload="handleDownload"
                />
            </div>
        </Toolbar>
        <employee-working-time-table
            v-if="employeeWorkTime.length && selectedEmployee"
            :workingTimes="this.employeeWorkTime"
            @onClick="handleEmployeeRowClick"
        />
        <working-time-table
            v-else-if="workingTimes.length"
            :workingTimes="this.workingTimes"
            @onClick="handleRowClick"
        />
        <div v-else-if="isFetchingData" class="Spinner-Wrap">
            <spinner />
        </div>
        <EmptyState v-else />
        <Modal
            title="Markieren Sie die Pausen"
            :show="pauseModalIsActive"
            :action="{ text: 'Speichern', color: 'green', callback: handleSavePauses }"
            :cancel="{ text: 'Abbrechen' }"
            :size="'medium'"
            :isLoading="isFetchingData"
            @onModalClose="handleModalClose"
        >
            <RowWrap width="auto">
                <template #header>
                    <Row
                        v-if="sortedPauses.length > 0"
                        :parentHover="false"
                        :items="{ row: header }"
                        :key="-1"
                        :index="-1"
                        class="Row-Header"
                        @onClickColumn="handlePausesHeaderClick"
                    />
                </template>
                <Row v-for="(pause, i) in sortedPauses" :key="i" :items="pause" :index="i" />
            </RowWrap>
        </Modal>
    </div>
</template>
<script type="text/javascript">
import axios from 'axios';
import { endOfMonth, startOfMonth, format, parseISO, isValid } from 'date-fns';

import { getDuration } from '@/lib/helper';

import Datepicker from '@/components/widgets/Datepicker';
import Download from '@/components/widgets/Download';
import Dropdown from '@/components/widgets/Dropdown';
import Modal from '@/components/widgets/Modal';
import Button from '@/components/widgets/Button';
import Headline from '@/components/Headline';
import Toolbar from '@/components/Toolbar';
import WorkingTimeTable from './components/WorkingTimeTable.vue';
import EmployeeWorkingTimeTable from './components/EmployeeWorkingTimeTable.vue';

import Spinner from '@/components/widgets/Spinner.vue';
import Row from '@/components/Row.vue';
import EmptyState from '@/components/EmptyState.vue';
import RowWrap from '@/components/RowWrap.vue';

export default {
    name: 'AppNavigation',
    components: {
        RowWrap,
        Modal,
        Datepicker,
        Download,
        Dropdown,
        Headline,
        Toolbar,
        WorkingTimeTable,
        Spinner,
        Button,
        Row,
        EmployeeWorkingTimeTable,
        EmptyState,
    },

    data: () => {
        return {
            pauseModalIsActive: false,
            isFetchingData: false,
            employeeWorkTime: [],
            workingTimes: [],
            currentPauses: [],
            changedPauses: [],
            drivers: JSON.parse(localStorage.getItem('drivers')).filter(d => d.isVisible),
            selectedEmployee: null,
            selectedFrom: startOfMonth(new Date()),
            selectedTo: endOfMonth(new Date()),
            sortStatePauses: {
                column: 'Dauer (HH:min)',
                ascending: true,
            },
        };
    },
    computed: {
        header() {
            return [
                { name: 'Dauer (HH:min)', value: null },
                { name: 'Start', value: null },
                { name: 'Ende', value: null },
                { name: 'Akzeptiert', value: null },
            ].map(header => {
                let indicator = '';
                if (this.sortStatePauses.column === header.name) {
                    indicator = this.sortStatePauses.ascending ? ' ▲' : ' ▼';
                }
                return { ...header, name: header.name + indicator };
            });
        },
        sortedPauses() {
            const { column, ascending } = this.sortStatePauses;
            return [...this.currentPauses].sort((a, b) => {
                // Get values for the current column for comparison
                const getRawValue = pause => {
                    const entry = pause.row.find(item => item.name.startsWith(column));
                    return entry ? entry.rawValue : undefined;
                };

                const aVal = getRawValue(a);
                const bVal = getRawValue(b);

                if (aVal === undefined && bVal === undefined) return 0;
                if (aVal === undefined) return ascending ? 1 : -1;
                if (bVal === undefined) return ascending ? -1 : 1;
                if (aVal < bVal) return ascending ? -1 : 1;
                if (aVal > bVal) return ascending ? 1 : -1;
                return 0;
            });
        },
    },
    methods: {
        handleShowOnPage(path) {
            const { selectedFrom, selectedTo, selectedCar, selectedEmployee } = this;
            const params = {
                from: format(selectedFrom, 'yyyy-MM-dd'),
                to: format(selectedTo, 'yyyy-MM-dd'),
                car: selectedCar?.value,
                driver: selectedEmployee?.value,
            };
            this.$router.push({ name: path, query: params });
        },
        handlePausesHeaderClick(column) {
            const columnName = column.name
                .replace('▲', '')
                .replace('▼', '')
                .trim();

            if (this.sortStatePauses.column === columnName) {
                this.sortStatePauses.ascending = !this.sortStatePauses.ascending;
            } else {
                this.sortStatePauses.column = columnName;
                this.sortStatePauses.ascending = true;
            }
        },
        handleRandomName() {
            const names = {
                0: `${fakerDE.person.firstName()} ${fakerDE.person.lastName()}`,
                1: `${fakerTR.person.firstName()} ${fakerTR.person.lastName()}`,
            };
            return names[Math.floor(Math.random() * 2)];
        },
        handleEmployeeRowClick({ index }) {},
        handleRowClick(driverNumber) {
            console.log('driverNumber', driverNumber);
            const workTime = this.workingTimes.find(wt => wt.driverNumber === driverNumber);
            console.log('workTime', workTime);
            const hasPauses = workTime.pause.length;
            console.log('hasPauses', hasPauses);
            if (!hasPauses) return;
            this.currentPauses = { ...workTime }.pause.map(p => ({
                row: [
                    {
                        name: 'Dauer (HH:min)',
                        value: getDuration(p.duration),
                        rawValue: p.duration,
                    },
                    {
                        name: 'Start',
                        value: format(parseISO(p.startAt), 'dd.MM.yy HH:mm'),
                        rawValue: p.startAt,
                    },
                    {
                        name: 'Ende',
                        value: format(parseISO(p.endAt), 'dd.MM.yy HH:mm'),
                        rawValue: p.endAt,
                    },
                    {
                        name: 'Akzeptiert',
                        props: {
                            keyName: 'accepted',
                            value: p.isAccepted,
                            uuid: p.uuid,
                            onCheck: checked => {
                                const pauseIndex = this.changedPauses.find(
                                    pause => pause.uuid === p.uuid,
                                );
                                const changedPause = { uuid: p.uuid, isActive: checked };
                                if (pauseIndex > -1) {
                                    this.changedPauses.splice(pauseIndex, 1, changedPause);
                                    return;
                                }
                                this.changedPauses.splice(0, 0, changedPause);
                            },
                        },
                        component: 'Checkbox',
                    },
                ],
            }));
            this.pauseModalIsActive = true;
        },
        async handleSavePauses() {
            if (!this.changedPauses.length) {
                this.changedPauses = [];
                this.handleModalClose();
                return;
            }
            const queryData = {
                pauses: this.changedPauses,
            };
            this.isFetchingData = true;
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/work_hours/pauses/set_checked_state`;
                const result = await axios.patch(url, queryData, {
                    withCredentials: true,
                });
                await this.handleQuery();
                this.changedPauses = [];
                this.handleModalClose();
            } catch (error) {
                this.$toasted.show('Etwas ist schief gelaufen, bitte versuchen Sie es erneut', {
                    type: 'error',
                });
            } finally {
                this.isFetchingData = false;
            }
        },
        handleModalClose() {
            this.pauseModalIsActive = false;
            setTimeout(() => (this.currentPauses = []), 400);
        },
        handleDownload(type) {
            const year = this.selectedFrom.getYear() + 1900;
            const month = ('0' + (this.selectedFrom.getMonth() + 1)).slice(-2);
            const id = this.selectedEmployee.id;
            const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/work_hours/${year}/${month}/driver/${id}/${type}`;
            window.open(url, '_blank');
        },
        handleDateChange(dateRange) {
            this.selectedFrom = dateRange.from;
            this.selectedTo = dateRange.to;
        },
        handleDriverChange({ item }) {
            console.log('item', item);
            this.selectedEmployee = item;
        },
        async handleQuery() {
            this.isFetchingData = true;
            if (this.selectedEmployee) {
                await this.fetchEmployeeWorkTimeData();
            } else {
                await this.fetchAllWorkTimeData();
            }
        },
        async fetchEmployeeWorkTimeData() {
            console.log('fetchEmployeeWorkTimeData', this.selectedEmployee);
            try {
                const queryData = {
                    rangeStartAt: this.selectedFrom,
                    rangeEndAt: this.selectedTo,
                };
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/work_hours/daily/driver/${this.selectedEmployee.id}`;
                const result = await axios.post(url, queryData, {
                    withCredentials: true,
                });
                this.employeeWorkTime = result.data;
            } catch (error) {
                this.$toasted.show('Etwas ist schief gelaufen, bitte versuchen Sie es erneut', {
                    type: 'error',
                });
            } finally {
                this.isFetchingData = false;
                this.$emit('onFinishLoading');
            }
        },
        async fetchAllWorkTimeData() {
            try {
                const queryData = {
                    rangeStartAt: format(this.selectedFrom, 'yy-MM-dd'),
                    rangeEndAt: format(this.selectedTo, 'yy-MM-dd'),
                };
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/work_hours`;
                const result = await axios.post(url, queryData, {
                    withCredentials: true,
                });
                this.workingTimes = result.data;
            } catch (error) {
                this.$toasted.show('Etwas ist schief gelaufen, bitte versuchen Sie es erneut', {
                    type: 'error',
                });
            } finally {
                this.isFetchingData = false;
                this.$emit('onFinishLoading');
            }
        },
    },
    mounted() {
        const query = this.$route.query;
        const { from, to, driver } = query;
        if (from && isValid(parseISO(from))) {
            this.selectedFrom = parseISO(from); // Only set if the parsed date is valid
        }
        if (to && isValid(parseISO(to))) {
            this.selectedTo = parseISO(to); // Only set if the parsed date is valid
        }
        if (driver) {
            this.selectedEmployee = this.drivers.find(e => e.value === driver) || null; // Fallback to null if not found
        }
        setTimeout(() => {
            this.handleQuery();
        }, 100);
    },
};
</script>
<style lang="scss">
.WorkingTime {
    @extend %contentWrapper;
    @extend %page;

    .Spinner-Wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 80px 0;
    }
}
</style>
